<template>
	<div class="schedule">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>車検</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">車検</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div class="block section-padding-50">
			<div class="page container">
				<div class="row">
					<div class="col-12 mb-5">
						<h3>ASSISTの車検</h3>
						<p>
							おススメはBMWの性能を充分に体感できるまで復元する整備ですが、ユーザーさまのご意向は様々なため、車両の使用状況に応じた整備内容をご提案します。<br />
							損傷や劣化したパーツの交換は保証があるBMW純正パーツ中心ですが、価格、性能、用途に応じてアフターパーツメーカーをおススメすることもございます。<br />
							<b
								>コストパフォーマンスと快適さを提案できるのはBMWチューニングショップの強みです。</b
							>
						</p>
						<p>
							経年で整備のボリュームも大きくなってくるため、車検で一括の整備をするよりも、半年ごとの点検をベースに整備を分散することをおススメします。<br />
							BMWメーカー保証が残っている車両で車検を検討されている場合はお気軽にご相談ください。<br />
						</p>
						<p class="text-center pt-3">
							<a
								href="https://form.run/@assist-kyoto"
								target="_blank"
								class="btn add-post-btn"
								>問い合わせ・ご予約</a
							>
						</p>
					</div>

					<div class="col-12 mb-5">
						<h3>ご用意頂くもの</h3>
						<ul class="ml-3">
							<li>車検証</li>
							<li>自賠責保険（現在有効なもの）</li>
							<li>納税証明書（今年度分）</li>
						</ul>
					</div>

					<div class="col-12 col-lg-6 mb-5">
						<h3>基本料金</h3>
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>基本作業</th>
									<th>金額（税抜）</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>法定24ヶ月点検</th>
									<td>￥29,000～￥78,000</td>
								</tr>
								<tr>
									<td class="text-right" colspan="2">
										<small
											>※車両モデルで価格が異なりますのでお問合わせください。</small
										>
									</td>
								</tr>
								<tr>
									<th>下廻スチーム洗浄/防錆塗装</th>
									<td>￥23,500</td>
								</tr>
								<tr>
									<th>ショートパーツ</th>
									<td>￥2,000</td>
								</tr>
								<tr>
									<th>保安確認検査料</th>
									<td>￥9,800</td>
								</tr>
								<tr>
									<th>検査代行手数料</th>
									<td>￥25,200</td>
								</tr>
								<tr class="table-primary">
									<th>車検セット割引</th>
									<th>▲￥7,650～▲￥15,000</th>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="col-12 col-lg-6 mb-5">
						<h3>割引</h3>
						<table class="table table-bordered">
							<caption>
								※ 車検時の追加メンテナンスは通常作業料の15％割引させて頂きます。
							</caption>
							<tbody>
								<tr class="table-primary">
									<td>追加作業料の割引</td>
									<th>15%割引</th>
								</tr>
							</tbody>
						</table>
						<h4>諸費用の税金</h4>
						<p>
							諸費用の税金は該当する「<b class="text-primary">1.重量税</b
							>」+「<b class="text-success">2.検査印紙</b>」+「<b
								class="text-danger"
								>3.自賠責保険</b
							>」の合計になります。
						</p>
					</div>

					<div class="col-12 mb-5">
						<h3>代車について</h3>
						<p>
							車両はBMWです。貸し出しはご予約が必要です。<br />※待ち状況は1～2ヶ月ぐらいです。まずはお問い合わせください。
						</p>
						<h6>代車費用（税抜）</h6>
						<table class="table table-bordered">
							<tbody>
								<tr>
									<th class="headline">通常入庫</th>
									<td>￥1,200／日</td>
								</tr>
								<tr>
									<th class="headline">車検入庫</th>
									<td>￥1,200／日（上限￥5,000）</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="row">
					<div class="col-12 mb-5">
						<p class="text-center pt-3">
							<a
								href="https://form.run/@assist-kyoto"
								target="_blank"
								class="btn add-post-btn"
								>問い合わせ・ご予約</a
							>
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
export default {
	title: "車検",
	components: {},
};
</script>

<style scoped lang="scss">
table {
	caption {
		font-size: 0.8rem;
	}
}
h4 {
	font-size: 1rem;
}

.table-bordered {
	thead {
		th {
			background-color: #f3f5f8;
		}
	}
	.headline {
		background-color: #f3f5f8;
	}
}
.table-primary {
	td,
	th {
		background-color: #daf3fb;
	}
}
</style>
